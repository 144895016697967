import React, { useRef } from "react";
import homeImage from "../Pages/assets/homelogo.svg";
import "./Home.css";

const InsurtechInnovationLab = () => {
  const aboutUsRef = useRef(null);

  const scrollToAboutUs = () => {
    if (aboutUsRef.current) {
      const offset = 0;
      const elementPosition = aboutUsRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <div className="container">
        <img
          alt="homeImage"
          src={homeImage}
          width={"90%"}
          height={"100%"}
          style={{ margin: "auto" }}
        />
      </div>
      <div className="scroll-button" onClick={scrollToAboutUs}>
        <div className="arrow"></div>
        <div className="scroll-text">Scroll Down</div>
      </div>
      <div ref={aboutUsRef} className="about-us">
        <div className="accordion"></div>
      </div>
    </div>
  );
};

export default InsurtechInnovationLab;
