import React, { useState } from "react";
import "./Leaders.css";
import leaderImage1 from "../Pages/assets/leaderdiskhdfc.svg";
import leaderImage2 from "../Pages/assets/leaderdiskiit.svg";
import leaderImage3 from "../Pages/assets/e74363000c95b5049083cd344f869353 copy.jpg";
import leaderImage4 from "../Pages/assets/55b875071f8b4df88553c30420b42a8a.jpg";

const leaders = [
  {
    icon: leaderImage1,
    photo: leaderImage3,
    name: "Ritesh Kumar",
    title: "MD & CEO",
    quote:
      '"Dynamism is one of our core values at HDFC ERGO. Living up to our values, we have been consistent in providing progress...."',
    fullQuote:
      ' "Dynamism is one of our core values at HDFC ERGO. Living up to our values, we have been consistent in providing progressive technology solutions. We have been focusing on evolving into an Al-first insurance company that will prove beneficial for our customers and have been adopting and implementing Artificial Intelligence(AI) and Machine Learning  (ML) in almost all of our operations. Associating with IIT Bombay will aid in accelerating this transformation and will further help us to differentiate our offerings in the industry. We are delighted to partner with such a prestigious institution and look forward to many successful projects with the Indian institute of Technology" ',
  },
  {
    icon: leaderImage2,
    photo: leaderImage4,
    name: "Prof. Subhasis Chaudhuri",
    title: "Director",
    quote:
      "IIT Bombay has been at the forefront of digital transformation undertaken by many large companies and brands with our on...",
    fullQuote:
      "IIT Bombay has been at the forefront of digital transformation undertaken by many large companies and brands with our ongoing focus on using technologies such as AI & ML. To solve real world problems this partnership is a natural fit. It gives us immense joy to work with HDFC Ergo.",
  },
];

const LeaderDesk = () => {
  const [expanded, setExpanded] = useState(Array(leaders.length).fill(false));

  const toggleQuote = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  return (
    <div className="leader-desk">
      <div className="leader-cards">
        {leaders.map((leader, index) => (
          <div key={index} className="leader-container">
            <div className="leader-info-overlay">
              <div className="leader-header">
                <img
                  src={leader.icon}
                  alt={`${leader.name} Icon`}
                  className="leader-icon"
                />
                <div>
                  <h2 className="leader-name">{leader.name}</h2>
                  <h3
                    className={
                      leader.title === "MD & CEO"
                        ? "leader-title-md-ceo"
                        : "leader-title-director"
                    }
                  >
                    {leader.title}
                  </h3>
                </div>
              </div>
            </div>
            <img
              src={leader.photo}
              alt={`Leader ${index + 1}`}
              className="leader-card-img"
            />
            <div className="leader-card-overlay">
              <div className="leader-quote-overlay">
                <p className="leader-quote-text">
                  {expanded[index] ? leader.fullQuote : leader.quote}
                </p>
                <button
                  className="leader-more-button"
                  onClick={() => toggleQuote(index)}
                >
                  {expanded[index] ? "X" : ">"}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeaderDesk;
