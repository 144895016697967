import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./TeamModal.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const teamMembers = [
  {
    image: require("../Pages/modal-img/Ritesh-kumar-modal-img.png"),
    category: "HDFC ERGO",
  },
  {
    image: require("../Pages/modal-img/Anuj-tyagi-modal-img.png"),
    category: "HDFC ERGO",
  },
  {
    image: require("../Pages/modal-img/Sriram-Naganathan-modal-img.png"),
    category: "HDFC ERGO",
  },
  {
    image: require("../Pages/modal-img/Parthanil-Ghosh-modal-img.png"),
    category: "HDFC ERGO",
  },
  {
    image: require("../Pages/modal-img/Anubhab-Goel-modal-img.png"),
    category: "HDFC ERGO",
  },
  {
    image: require("../Pages/modal-img/Drishan-Dang-modal-img.png"),
    category: "HDFC ERGO",
  },
  {
    image: require("../Pages/modal-img/Ravishankar-Gedala-modal-img.png"),
    category: "HEIITB",
  },
  {
    image: require("../Pages/modal-img/Nidhi-Mathur-modal-img.png"),
    category: "HEIITB",
  },
  {
    image: require("../Pages/modal-img/Prof. Rohan-Chinchwadkar-modal-img.png"),
    category: "HEIITB",
  },
  {
    image: require("../Pages/modal-img/Sneha-Bajaj-modal-img.png"),
    category: "HEIITB",
  },
  {
    image: require("../Pages/modal-img/Milind-Shinde-modal-img.png"),
    category: "HEIITB",
  },
];

const TeamModal = ({ open, handleClose }) => {
  const [activeFilter, setActiveFilter] = useState("All");

  const filteredMembers =
    activeFilter === "All"
      ? teamMembers
      : teamMembers.filter((member) => member.category === activeFilter);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="team-modal-title"
      aria-describedby="team-modal-description"
    >
      <Box sx={modalStyle}>
        <div className="team-modal-header">
          <h2 id="team-modal-title">Our Team</h2>
          <div className="filter-btn-group">
            <button
              className={`filter-btn ${activeFilter === "All" ? "active" : ""}`}
              onClick={() => setActiveFilter("All")}
            >
              All
            </button>
            <button
              className={`filter-btn ${
                activeFilter === "HDFC ERGO" ? "active" : ""
              }`}
              onClick={() => setActiveFilter("HDFC ERGO")}
            >
              HDFC ERGO
            </button>
            <button
              className={`filter-btn ${
                activeFilter === "HEIITB" ? "active" : ""
              }`}
              onClick={() => setActiveFilter("HEIITB")}
            >
              HEIITB
            </button>
          </div>
          <IconButton onClick={handleClose} className="close-btn">
            <CloseIcon />
          </IconButton>
        </div>

        <div className="team-modal-grid">
          {filteredMembers.map((member, index) => (
            <div className="team-modal-card" key={index}>
              <img
                src={member.image}
                alt={`Team Member ${index + 1}`}
                className="team-modal-img"
              />
            </div>
          ))}
        </div>
      </Box>
    </Modal>
  );
};

export default TeamModal;
