import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import TeamModal from "./TeamModal";
import "./ourteam.css";

const teamMembers = [
  { image: require("../Pages/ourteam_img/ritesh kumar.png") },
  { image: require("../Pages/ourteam_img/anuj tyagi.png") },
  { image: require("../Pages/ourteam_img/Sriram Naganathan.png") },
  { image: require("../Pages/ourteam_img/Parthanil Ghosh.png") },
  { image: require("../Pages/ourteam_img/Anubhab Goel.png") },
  { image: require("../Pages/ourteam_img/Drishan Dang.png") },
  { image: require("../Pages/ourteam_img/Ravishankar Gedala.png") },
  { image: require("../Pages/ourteam_img/Nidhi Mathur.png") },
  { image: require("../Pages/ourteam_img/Prof. Rohan Chinchwadkar.png") },
  { image: require("../Pages/ourteam_img/Sneha Bajaj.png") },
  { image: require("../Pages/ourteam_img/Milind Shinde.png") },
];

const TeamCarousel = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="bg_img">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        slidesPerView={4}
        loop={true}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
      >
        {teamMembers.map((res, index) => (
          <SwiperSlide key={index}>
            <img alt={`Team Member ${index + 1}`} src={res?.image} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>

      <button className="see-all-btn" onClick={handleOpen}>
        See All
      </button>

      <TeamModal open={open} handleClose={handleClose} />
    </div>
  );
};

export default TeamCarousel;
