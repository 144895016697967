import React, { useState } from 'react';
import './accomplish.css';
import background from '../Pages/assets/accomplishlogo.svg';

const Accomplishments = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleInfoClick = () => {
    setIsFlipped(!isFlipped);
  };

  const handleMouseLeave = () => {
    setIsFlipped(false);
  };

  return (
    <div className="accomplishments" style={{ backgroundImage: `url(${background})` }}>
      <div className="cards11">
        <div className="card44">
          <div className="number66">28</div>
          <div className="text">Project Proposal Received</div>
        </div>
        <div className="card44">
          <div className="number66">5</div>
          <div className="text">Project Initiated</div>
        </div>
        <div
          className={`card44 flip-container ${isFlipped ? 'flipped' : ''}`}
          onMouseLeave={handleMouseLeave}
        >
          <div className="flipper">
            <div className="front">
              <div className="number66">4</div>
              <div className="text">Focus Area Covered</div>
            </div>
            <div className="back">
              <div className="back-content">
                <div>Health tech</div>
                <div>Climate Tech</div>
                <div>Road safety</div>
                <div>Customer experience</div>
              </div>
            </div>
          </div>
          <div className="info-button" onClick={handleInfoClick}>i</div>
        </div>
      </div>
    </div>
  );
};

export default Accomplishments;
