import React from 'react';
import './Focusarea.css'; 
import Card from '../CommonComponents/Commoncard';
import imagebrain from '../Pages/assets/brainaiml.svg';
import fintech from '../Pages/assets/fintech.svg';
import healthtech from '../Pages/assets/healthtech.svg';
import climatetech from '../Pages/assets/climatetech.svg';
import genai from '../Pages/assets/genai.svg';
import otherarea from '../Pages/assets/otherarea.svg';

const focusAreas = [
  {
    icon: imagebrain, 
    title: "AI & ML",
    description: "AI and ML are driving transformative changes in India. They enhance early disease detection in healthcare, improve customer service and security in finance, optimize agriculture for higher yields, and personalize education. AI and ML are transforming the insurance sector by improving risk assessment, fraud detection, customer service, and many other aspects of the industry.",
  },
  {
    icon: fintech,
    title: "FinTech",
    description: "Fintech is revolutionizing the Indian financial market. It encompasses a wide range of digital innovations, from mobile payments and digital lending to robo-advisors and blockchain-based solutions. Fintech has brought about transformative changes in the insurance industry by enhancing distribution, improving risk assessment, automating processes, creating innovative products and much more.",
  },
  {
    icon: healthtech,
    title: "Health Tech",
    description: "A rapidly advancing field in India to transform the healthcare ecosystem, solutions ranging from tele-medicine, remote consultations, AI-diagnostic tools, Wearable devices, etc. Such innovations improve access to healthcare, especially in rural areas, with enhances accuracy. In the insurance industry, it helps offer personalized plans and wellness solutions, ultimately improves access to care.",
  },
  {
    icon: climatetech,
    title: "Climate Tech",
    description: "Climate tech plays a crucial role across multiple industries, including the insurance by helping in mitigation of risks associate with climate change. They enable better prediction of climatic events to enhance underwriting accuracy and development of innovative solutions tailored to climate-related risk.",
  },
  {
    icon: genai,
    title: "Gen AI",
    description: "Generative AI helps to create things like reports, documents, or even images. In the insurance industry, it can help make paperwork faster and more accurate, like generating insurance policies or claim documents automatically. This saves time and makes insurance processes smoother.",
  },
  {
    icon: otherarea,
    title: "Other Areas",
    description: "Our focus entends beyond these primary sectors to encompass a wide array of industries, all driven by research and innovation.",
  }
];

const FocusArea = () => {
  return (
    <div className="focus-area">
      <div className="focus-cards">
        {focusAreas.map((area, index) => (
          <Card
            key={index}
            icon={area.icon}
            title={area.title}
            description={area.description}
          />
        ))}
      </div>
    </div>
  );
};

export default FocusArea;
