import React from 'react';
import './Footer.css';
import mapimg from './location.png';
import mailogo from './maillogo.png';
import pointerIcon from './point.svg';

const Footer = () => {
  // Function to handle map click
  const handleMapClick = () => {
    window.open(
      "https://www.google.com/maps?q=IIT+Bombay,+Powai,+Mumbai+-+400076&hl=en",
      "_blank",
      "noopener noreferrer"
    );
  };

  return (
    <div id="footer" className="footer"> 
      <h1>Contact Us</h1>
      <p>Let's get connected. Simply write to us, and we will reach out to you.</p>
      
      <div className="contact-info">
        <div className="map-container">
          <div className="map-wrapper">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.4220424960495!2d72.91403321171488!3d19.132994596944634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c7f189efc039%3A0x68fdcea4c5c5894e!2sIndian%20Institute%20of%20Technology%20Bombay!5e0!3m2!1sen!2sin!4v1725010877761!5m2!1sen!2sin&maptype=roadmap&disableDefaultUI=true&zoomControl=false&scaleControl=false&mapTypeControl=false&streetViewControl=false&fullscreenControl=false&gestureHandling=none&scrollwheel=false"
              width="400"
              height="370"
              style={{ border: '1px solid transparent' }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="map-overlay" onClick={handleMapClick}>
              <img src={pointerIcon} alt="Pointer Icon" className="pointer-icon" />
              <p className="custom-map-text">Click to see on map</p>
            </div>
          </div>
        </div>
        
        <div className="details">
          <div className="detail-item">
            <img src={mapimg} alt="Location Icon" className="icon" />
            <div>
              <h5>HEIITB Lab Address</h5>
              <p>IIT Bombay, Powai, Mumbai - 400076</p>
            </div>
          </div>
          <div className="detail-item">
            <img src={mailogo} alt="Email Icon" className="icon" />
            <div>
              <h3>HEIITB Email</h3>
              <p>office.heiitb@iitb.ac.in</p>
            </div>
          </div>
        </div>
      </div>
      
      <footer>
        <p>Copyright © 2024 HEIITB. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Footer;
