import React, { useState } from "react";
import aboutUsImg from "../Pages/assets/aboutlogo.svg";
import "./About.css";

const accordionData = [
  {
    title: "Who are we?",
    content:
      "HDFC ERGO General Insurance, India's leading private sector general insurance company has partnered with the Indian Institute of Technology Bombay (IIT Bombay), an 'Institution of Eminence' (IoE), to establish the first-of-its-kind Insurtech innovation lab, the 'HEITB Innovation Lab', located at IITB Campus in Powai, Mumbai.",
  },
  {
    title: "What we do?",
    content:
      "This collaboration marks a pioneering initiative within the Indian insurance sector, with an objective to work on high-impact projects spanning across the entire insurance value chain to craft solutions to address pertinent business challenges.",
  },
  {
    title: "Why we do it?",
    content:
      "A lot can be achieved with this Edu-Corp relationship. Harnessing the educational expertise and merging it with industry expertise is the need of the hour. This strategic association is expected to focus on research and development in emerging domains, thereby contributing value to the sector as a whole. To achieve this, We work on a variety of focus areas with an aim to build future-ready industry solutions.",
  },
  {
    title: "How we do it?",
    content:
      "The HEIITB Innovation lab serves as a platform to foster interactions, conduct brainstorming sessions and offer the necessary workspaces for students, professors and HDFC ERGO teams. By conducting regular CFPs (call for proposal) to ideate and co-create innovative projects, the HEIITB Innovation Lab creates an avenue to facilitate direct interaction and engagement between IIT Bombays Professors/Students and HDFC ERGO, to leverage education and industry expertise.",
  },
];

const AboutUs = () => {
  const [selected, setSelected] = useState(null);

  const toggleAccordion = (index) => {
    setSelected(selected === index ? null : index);
  };

  return (
    <div className="about-us">
      <div className="card1">
        <img src={aboutUsImg} alt="aboutUs" className="about-img" />
      </div>
      <div className="accordion_container">
        <div className="accordion">
          {accordionData.map((item, index) => (
            <div key={index} className="accordion-item">
              <div
                className={`accordion-title ${
                  selected === index ? "active" : ""
                }`}
                onClick={() => toggleAccordion(index)}
              >
                <div className="number_title">
                  <span className="number">
                    {index + 1 < 10 ? "0" : ""}
                    {index + 1}
                  </span>
                  <h2>{item.title}</h2>
                </div>
                <span>{selected === index ? "-" : "+"}</span>
              </div>
              <div
                className={`accordion-content ${
                  selected === index ? "show" : ""
                }`}
              >
                <p>{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
