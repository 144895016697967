import React from "react";
import style from "./Commoncard.module.css";

const Commoncard = ({ icon, title, description, customClass }) => {
  return (
    <div className={style.card}>
    <div className={style.customClass}></div>
      <div className={style.header}>
        <img src={icon} alt={title} className={`${style.icon} ${customClass}`} />
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
    </div>
  );
};

export default Commoncard;
