import React from 'react';
import './media.css';
import image1 from '../Pages/assets/Analytic soln 1.png';
import image2 from '../Pages/assets/Analytic soln 2.png';
import image3 from '../Pages/assets/Analytic soln 3.png';
import image4 from '../Pages/assets/Analytic soln 4.png';
import image5 from '../Pages/assets/Analytic soln 5.png';
import image6 from '../Pages/assets/Analytic soln 6.png';

const Media = () => {
    const cards = [
        { image: image1, title: 'Analytic Solutions', subtitle: 'IT Technology' },
        { image: image2, title: 'Analytic Solutions', subtitle: 'IT Technology' },
        { image: image3, title: 'Analytic Solutions', subtitle: 'IT Technology' },
        { image: image4, title: 'Analytic Solutions', subtitle: 'IT Technology' },
        { image: image5, title: 'Analytic Solutions', subtitle: 'IT Technology' },
        { image: image6, title: 'Analytic Solutions', subtitle: 'IT Technology' },
    ];

    return (
        <div className="section-wrapper">
            <div className="cards-wrapper">
                {cards.map((card, index) => (
                    <div className="card" key={index}>
                        <div className="card-inner">
                            <img src={card.image} alt={card.title} />
                            <div className="card-content">
                                <h3>{card.title}</h3>
                                <p>{card.subtitle}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Media;
