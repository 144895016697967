import React from 'react';
import Navbar from './components/Navbar';
import About from './Pages/About';
import Footer from './components/Footer';
import Leaders from './Pages/Leaders';
import FocusArea from './Pages/Focusarea';
import Accomplishments from './Pages/accomplish';
import OurTeam from './Pages/ourteam';
import EventsSection from './Pages/events';
import MediaArea from './Pages/media';
import Home from './Pages/Home';
import ScrollToTopButton from './Pages/ScrollToTopButton'; // Import the new component

function App() {
    return (
        <div className="App">
            <Navbar />
            <section id="home">
                <Home />
            </section>
            <section id="about-us">
                <About />
            </section>
            <section id="leaders-desk">
                <Leaders />
            </section>
            <section id="focus-area">
                <FocusArea />
            </section>
            <section id="accomplishments">
                <Accomplishments />
            </section>
            <section id="our-team">
                <OurTeam />
            </section>
            <section id="events">
                <EventsSection />
            </section>
            <section id="media">
                <MediaArea />
            </section>
            <Footer />
            <ScrollToTopButton /> {/* Add the scroll-to-top button */}
        </div>
    );
}

export default App;
