  import React from 'react';
  import './events.css';
  import laptopimg from '../Pages/assets/laptop.png';
  import ipadimg from '../Pages/assets/ipad.png';
  import manworkingimg from '../Pages/assets/manworking.png';

  const EventHeader = () => {
    const events = [
      {
        icon: ipadimg,
        title: 'Detailed insights for your social media',
        description: 'Lorem Ipsum is simply dummy text the printing and typesetting industry. Lorem Ipsum has been the standard dummy.',
        date: 'December 05, 2021',
        readTime: '3 Min Read',
        tags: ['Analyze', 'Marketing'],
      },
      {
        icon: manworkingimg,
        title: 'New Device Invention for Digital Platform',
        description: 'Lorem Ipsum is simply dummy text the printing and typesetting industry. Lorem Ipsum has been the standard dummy.',
        date: 'December 05, 2021',
        readTime: '3 Min Read',
        tags: ['Analyze'],
      },
      {
        icon: laptopimg,
        title: 'Business Strategy Make His Goal Acheive',
        description: 'Lorem Ipsum is simply dummy text the printing and typesetting industry. Lorem Ipsum has been the standard dummy.',
        date: 'December 05, 2021',
        readTime: '3 Min Read',
        tags: ['Analyze', 'Marketing'],
      },
    ];

    return (
      <div className="event-header">
        <div className="header-content"></div>
        <div className="header-tabs">
          <button className="tab active">Completed</button>
          <button className="tab">Upcoming</button>
        </div>
        <div className="event-cards">
          {events.map((event, index) => (
            <div className="event-card-wrapper">
            <div className="event-card">
              <div className="event-icon-wrapper">
                <img src={event.icon} alt={event.title} className="event-icon" />
              </div>
              <div className="completed-tag">Completed</div>
              <div className="tags">
                {event.tags.map((tag, idx) => (
                  <span key={idx} className={`tag ${tag === 'Completed' ? 'completed' : ''}`}>{tag}</span>
                ))}
              </div>
              <div className="card-footer">
                <span>{event.date}</span>
                <span>{event.readTime}</span>
              </div>
              
              <hr></hr>
              <h3>{event.title}</h3>
              <div className='card-footer'>
                <p>{event.description}</p>
              </div>
            </div>
            <button className="view-more-button">View More</button>
          </div>
          ))}
        </div>
      </div>
    );
  };

  export default EventHeader;
